import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginActivate } from './shared/authGuard';
import { LoginComponent } from './login/login.component';
import { WochenplanComponent } from './wochenplan/wochenplan.component';
import { MealListComponent } from './meal/meal-list/meal-list.component';
import { MealEditComponent } from './meal/meal-edit/meal-edit.component';
import { MealAddComponent } from './meal/meal-add/meal-add.component';
import { Role } from './models/roles';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'wochenplan',
        data: { title: 'Start' }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login'
        }
    },
    {
        path: 'wochenplan',
        component: WochenplanComponent,
        canActivate: [LoginActivate],
        data: {
            title: 'Wochenplan',
            button: {
                route: 'gerichte/new',
                title: 'add'
            }
        }
    },
    {
        path: 'gerichte',
        component: MealListComponent,
        canActivate: [LoginActivate],
        data: { title: 'Übersicht Mahlzeiten' }
    },
    {
        path: 'gerichte/new',
        component: MealAddComponent,
        canActivate: [LoginActivate],
        canLoad: [LoginActivate],
        data: {
            title: 'Neuen Eintrag erstellen',
            roles: [Role.Admin]
        }
    },
    {
        path: 'gerichte/:idMeal/edit',
        component: MealEditComponent,
        canActivate: [LoginActivate],
        canLoad: [LoginActivate],
        data: {
            title: 'Eintrag bearbeiten',
            roles: [Role.Admin]
        }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [LoginActivate],
        data: {
            title: 'Abmelden'
        }
    },
    {
        path: '**',
        component: ErrorComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
