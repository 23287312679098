import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

export interface DialogData {
    title: string;
    message: string;
    next: string;
}


@Component({
    selector: 'app-dialog-success',
    templateUrl: 'dialog-success.html',
})

export class DialogSuccessComponent {

    public title: string;
    public message: string;
    public next: string;

    constructor(private dialogRef: MatDialogRef<DialogSuccessComponent>,
                private router: Router,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.title = data.title;
        this.message = data.message;
        this.next = data.next ? data.next : 'wochenplan' ;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
        this.router.navigate([this.next]);
    }

}
