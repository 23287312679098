import {Component, OnInit} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationError, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogSuccessComponent} from './dialog/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'App Menulist';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                const child: any = this.activatedRoute.firstChild;
                if (child.snapshot.data.title) {
                    return child.snapshot.data.title;
                }
            })
        ).subscribe((ttl: string) => {
            this.titleService.setTitle(`App Menulist - ${ttl}`);
        });

    }
}
