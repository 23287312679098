import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogSuccessComponent} from '../dialog/dialog';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(private router: Router, private dialog: MatDialog) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            id: 1,
            title: 'Keine Zugriffsrechte',
            message: 'Leider darfst du hier nichts ändern. Du wirst nun wieder zurück gebracht...',
            next: 'wochenplan'
        };
        this.dialog.open(DialogSuccessComponent, dialogConfig);
    }

    ngOnInit(): void {
    }


}
