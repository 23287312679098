import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../shared/auth.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogSuccessComponent} from '../dialog/dialog';
import {IAuthUser} from '../models/auth';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup;

    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private dialog: MatDialog) {

        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login(): void {
        const val = this.loginForm.value;
        if (val.username && val.password) {
            this.authService.login(val.username, val.password).subscribe((result) => {
                const userLocalStorage: string = localStorage.getItem('user') ? localStorage.getItem('user') as string : '{}';
                const user: IAuthUser = JSON.parse(userLocalStorage);
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data = {
                    id: 1,
                    title: 'Anmeldung erfolgreich',
                    message: `Hallo ${user.firstname}, du bist nun erfoglreich angemledet!`
                };
                this.dialog.open(DialogSuccessComponent, dialogConfig);
                /*if (this.authService.isLoggedIn()) {
                    this.router.navigate(['wochenplan']);
                }*/
            });
        }
    }

    ngOnInit(): void {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['wochenplan']);
        }
    }

}
