import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Role} from '../models/roles';

@Injectable({
    providedIn: 'root'
})
export class LoginActivate implements CanActivate, CanLoad {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['login']);
            return false;
        }
        const roles = route.data.roles as Role[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            this.router.navigate(['error', 'not-found']);
            return false;
        }
        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {

        if (!this.authService.isLoggedIn()) {
            window.alert('You don\'t have permission to view this page');
            return false;
        }
        const roles = route.data && route.data.roles as Role[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            window.alert('You don\'t have permission to view this page');
            return false;
        }

        return true;
    }
}
