import { Component, OnChanges, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    pageTitle: string;
    toolbarButton: { route: string, title: string, buttonType: string } = { route: '', title: '', buttonType: '' }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private breakpointObserver: BreakpointObserver, private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.pageTitle = ''
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                console.log(this.activatedRoute.firstChild)
                const child: any = this.activatedRoute.firstChild;
                if (child.snapshot.data) {
                    return child.snapshot.data;
                }
            })
        ).subscribe((data: any) => {
            this.toolbarButton = { route: '', title: '', buttonType: '' }
            if (data.title) {
                this.pageTitle = data.title;
            }
            if (data.button) {
                this.toolbarButton = data.button;
            }
        });
    }

    buttonRoute(): void {
        if (this.toolbarButton) {
            this.router.navigate([this.toolbarButton.route]);
        }
    }

}
