import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AngularMaterialModule} from './angular-material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LayoutModule} from '@angular/cdk/layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {DialogSuccessComponent} from './dialog/dialog';


import {LoginComponent} from './login/login.component';
import {NavComponent} from './nav/nav.component';
import {AuthInterceptor} from './shared/auth.interceptor';
import {LoginActivate} from './shared/authGuard';
import {WochenplanComponent} from './wochenplan/wochenplan.component';
import {MealListComponent} from './meal/meal-list/meal-list.component';
import {MealDetailComponent} from './meal/meal-detail/meal-detail.component';
import {MealEditComponent} from './meal/meal-edit/meal-edit.component';
import {MealAddComponent} from './meal/meal-add/meal-add.component';
import {FilterPipe} from './shared/filter.pipe';
import {LogoutComponent} from './logout/logout.component';
import {ErrorComponent} from './error/error.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavComponent,
        WochenplanComponent,
        MealListComponent,
        MealDetailComponent,
        MealEditComponent,
        MealAddComponent,
        DialogSuccessComponent,
        FilterPipe,
        LogoutComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularMaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
        LoginActivate, Title,
        MatDatepickerModule,
        MatNativeDateModule,
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'}],
    bootstrap: [AppComponent]
})
export class AppModule {
}
