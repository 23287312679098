<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a mat-list-item href="/wochenplan">Wochenplan</a>
            <a mat-list-item href="/gerichte">Gerichte</a>
            <a mat-list-item href="/logout">Abmelden</a>
            <!--<a mat-list-item href="#">Abmelden</a>-->
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="contentWrapper">
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span>{{pageTitle}}</span>
            <button *ngIf="toolbarButton.title" mat-mini-fab class="toolbar__button" color="white"
                (click)="buttonRoute()">
                <mat-icon>{{toolbarButton.title}}</mat-icon>
            </button>
        </mat-toolbar>
        <!-- Add Content Here -->
        <div class="contentBox py-3 px-2">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>