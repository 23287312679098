import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../shared/api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { IMeal } from '../models/meals';

@Component({
    selector: 'app-wochenplan',
    templateUrl: './wochenplan.component.html',
    styleUrls: ['./wochenplan.component.scss']
})
export class WochenplanComponent implements AfterViewInit, OnInit {
    public allMeals: any = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    today: any;
    fromDate: any;
    toDate: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['date_to', 'breakfast', 'lunch', 'dinner', 'edit'];

    constructor(private httpClient: HttpClient, private apiService: ApiService, private router: Router) {
        moment.updateLocale('de', {
            week: {
                dow: 6, // First day of week is Sunday
                doy: 7  // First week of year must contain 1 January (7 + 0 - 1)
            }
        });
        this.today = moment();
        this.fromDate = this.today.startOf('week').format('YYYY-MM-DD');
        this.toDate = this.today.endOf('week').format('YYYY-MM-DD');
    }

    ngOnInit(): any {
        this.getMeals(this.fromDate, this.toDate);
    }

    createNew(): void {
        this.router.navigate(['gerichte/new']);
    }

    getMeals(fromDate: any, toDate: any): void {
        this.apiService.getMeals(fromDate, toDate).pipe(takeUntil(this.destroy$))
            .subscribe((meals) => {
                this.allMeals = meals;
            });
    }

    getNext(event: PageEvent): void {
        if (event.pageIndex === 0) {
            const fromDate = moment().subtract(14, 'd').startOf('week').format('YYYY-MM-DD');
            const toDate = moment().subtract(14, 'd').endOf('week').format('YYYY-MM-DD');
            this.getMeals(fromDate, toDate);
        } else if (event.pageIndex === 1) {
            const fromDate = moment().subtract(7, 'd').startOf('week').format('YYYY-MM-DD');
            const toDate = moment().subtract(7, 'd').endOf('week').format('YYYY-MM-DD');
            this.getMeals(fromDate, toDate);
        } else if (event.pageIndex === 3) {
            const fromDate = moment().add(7, 'd').startOf('week').format('YYYY-MM-DD');
            const toDate = moment().add(7, 'd').endOf('week').format('YYYY-MM-DD');
            this.getMeals(fromDate, toDate);
        } else {
            this.getMeals(this.fromDate, this.toDate);
        }
    }

    ngAfterViewInit(): any {

    }

    formatDate(date: string) {
        return moment(date).format('dd (DD.MM)')
    }

    isToday(meal: IMeal): boolean {
        const mealDate = new Date(meal.date_to)
        const todaysDate = new Date()
        return mealDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
    }
}
