import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/api.service';
import {HttpClient} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {IMeal, IMealList} from '../../models/meals';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-meal-list',
    templateUrl: './meal-list.component.html',
    styleUrls: ['./meal-list.component.scss']
})
export class MealListComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    meals: IMeal[];
    sortedMealList: IMealList[];
    filterValue: string;
    filterArgs: { kind: string };
    buttonGroupInit: string;

    constructor(private httpClient: HttpClient, private apiService: ApiService) {
    }

    onValChange(val: string): void {
        if (val !== 'Alle') {
            this.filterArgs = {kind: val};
        } else {
            this.filterArgs = {kind: ''};
        }
    }

    ngOnInit(): void {
        this.getMeals();
    }

    getMeals(): void {
        this.apiService.getMeals().pipe(takeUntil(this.destroy$))
            .subscribe((meals) => {
                this.meals = meals;
                this.meals.forEach((e: any) => {
                    e.date_to = moment(e.date_to).format('DD.MM.YYYY');
                });
                this.sortedMealList = [];
                const mealTypes: any[] = ['breakfast', 'lunch', 'dinner'];
                this.meals.forEach((element: any) => {
                    mealTypes.forEach(search => {
                        let colorVal;
                        let kindVal;
                        let iconVal;
                        switch (search) {
                            case 'breakfast':
                                colorVal = 'primary';
                                kindVal = 'Frühstück';
                                iconVal = 'bakery_dining';
                                break;
                            case 'lunch':
                                colorVal = 'accent';
                                kindVal = 'Mittag';
                                iconVal = 'lunch_dining';
                                break;
                            case 'dinner' :
                                colorVal = 'warn';
                                kindVal = 'Abendbrot';
                                iconVal = 'bedtime';
                                break;
                            default:
                                colorVal = 'primary';
                                kindVal = 'Frühstück';
                                iconVal = 'bakery_dining';
                        }
                        const index = this.sortedMealList.findIndex((e) => e.title === element[`${search}`]);
                        if (index === -1 && element[`${search}`] !== '') {
                            this.sortedMealList.push({
                                title: element[`${search}`],
                                color: colorVal,
                                kind: kindVal,
                                icon: iconVal
                            });
                        }
                    });
                });
            });

    }

    sortBy(prop: any): any {
        if (this.sortedMealList) {
            return this.sortedMealList.sort((a, b) => a.title > b.title ? 1 : a.title === b.title ? 0 : -1);
        }
    }

}
