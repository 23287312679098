import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import * as moment from 'moment';

import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

import {IUsers} from '../models/users';
import {Role} from '../models/roles';

import {IAuth, IAuthUser} from '../models/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userSubject: BehaviorSubject<IAuthUser>;
    public user: Observable<IAuthUser>;
    private loginUrl = '/api/login';  // URL to web api

    constructor(public http: HttpClient) {
        const user = localStorage.getItem('user') ? localStorage.getItem('user') as string : '{}';
        this.userSubject = new BehaviorSubject<IAuthUser>(JSON.parse(user));
        this.user = this.userSubject.asObservable();
    }

    public login(username: string, password: string): Observable<any> {
        const options = {headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})};
        return this.http.post<IAuth>(this.loginUrl, {username, password}, options)
            .pipe(map(result => this.setSession(result)), catchError(this.handleError), shareReplay());
    }

    private setSession(authResult: IAuth): void {
        const expiresAt = moment().add(authResult.expiresIn, 'second');

        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        localStorage.setItem('user', JSON.stringify(authResult.user));
    }

    logout(): void {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user');
    }

    public isLoggedIn(): boolean {
        if (this.getExpiration()) {
            return moment().isBefore(this.getExpiration());
        } else {
            return false;
        }
    }

    hasRole(role: Role): boolean {
        return this.isLoggedIn() && this.userSubject.getValue().id_role === role;
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration: any = localStorage.getItem('expires_at');
        const token: any = localStorage.getItem('id_token');
        if (expiration && token) {
            return moment(JSON.parse(expiration));
        } else {
            return false;
        }
    }

    handleError(error: HttpErrorResponse): Observable<any> {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
