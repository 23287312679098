<div class="px-2">
    <div class="login-wrapper col-12">
        <mat-card class="box col-12 col-md-6 offset-md-3">
            <mat-card-header>
                <mat-card-title>Edit</mat-card-title>
            </mat-card-header>
            <form class="example-form" [formGroup]="mealEdit">
                <mat-card-content class="row">
                    <input type="hidden" formControlName="id_meal">
                    <input type="hidden" formControlName="id_user">
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Datum</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Datum" formControlName="date_to">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Früh</mat-label>
                        <input matInput placeholder="Früh" [matAutocomplete]="autoBreakfast"
                            formControlName="breakfast">
                        <mat-autocomplete autoActiveFirstOption #autoBreakfast="matAutocomplete">
                            <mat-option *ngFor="let option of filteredBreakfastOptions | async" [value]="option.title">
                                {{option.title}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Mittag</mat-label>
                        <input matInput placeholder="Mittag" [matAutocomplete]="autoLunch" formControlName="lunch">
                        <mat-autocomplete autoActiveFirstOption #autoLunch="matAutocomplete">
                            <mat-option *ngFor="let option of filteredLunchOptions | async" [value]="option.title">
                                {{option.title}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Abend</mat-label>
                        <input matInput placeholder="Abend" [matAutocomplete]="autoDinner" formControlName="dinner">
                        <mat-autocomplete autoActiveFirstOption #autoDinner="matAutocomplete">
                            <mat-option *ngFor="let option of filteredDinnerOptions | async" [value]="option.title">
                                {{option.title}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </mat-card-content>
                <button mat-flat-button color="primary" (click)="edit()"
                    [disabled]="mealEdit.invalid">Speichern</button>
            </form>

            <div class="isLoading" *ngIf="showLoading">
                <mat-spinner></mat-spinner>
            </div>
        </mat-card>
    </div>
</div>