import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, retry, shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private usersUrl = '/api/users';  // URL to web api
    private mealsUrl = '/api/meals';  // URL to web api for all meals

    constructor(public http: HttpClient) {
    }

    handleError(error: HttpErrorResponse): Observable<any> {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    getMeals(fromDate?: string | null, toDate?: string | null, idMeals?: number, com?: string): Observable<any> {
        // Add safe, URL encoded_page parameter
        let options = {params: new HttpParams()};
        if (fromDate && toDate) {
            options = {params: new HttpParams({fromString: `fromDate=${fromDate}&toDate=${toDate}`})};
        }
        if (idMeals) {
            return this.http.get<any[]>(`${this.mealsUrl}/${idMeals}`).pipe(retry(3), catchError(this.handleError));
        } else {
            return this.http.get<any[]>(this.mealsUrl, options).pipe(retry(3), catchError(this.handleError));
        }
    }

    postMeals(values: []): Observable<any> {
        const options = {headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})};
        return this.http.post<any[]>(`${this.mealsUrl}`, {values}, options)
            .pipe(retry(3), catchError(this.handleError), shareReplay());
    }

}
