<div class="container">
    <div class="d-flex justify-content-end">
        <mat-button-toggle-group class="meal-list-filter"
                                 #groupMealList="matButtonToggleGroup"
                                 value="Alle"
                                 (change)="onValChange(groupMealList.value)">
            <mat-button-toggle  value="Alle" [checked]="true">
                <mat-icon>all_inclusive</mat-icon>
                Alle
            </mat-button-toggle>
            <mat-button-toggle value="Frühstück">
                <mat-icon color="primary">bakery_dining</mat-icon>
                Frühstück
            </mat-button-toggle>
            <mat-button-toggle value="Mittag">
                <mat-icon color="accent">lunch_dining</mat-icon>
                Mittag
            </mat-button-toggle>
            <mat-button-toggle value="Abendbrot">
                <mat-icon color="warn">bedtime</mat-icon>
                Abendbrot
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="meal-list row mt-3">
        <div *ngFor="let meal of sortedMealList of sortBy('title')| filterByKind: filterArgs"
             class="meal-list-card col-12 col-sm-6 col-lg-4 col-xl-3">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar>
                        <mat-icon class="meal-list-card-avatar" [color]="meal.color">{{meal.icon}}</mat-icon>
                    </div>
                    <mat-card-title class="meal-list-card-title" color="meal.color">{{meal.title}}</mat-card-title>
                    <mat-card-subtitle>{{meal.kind}}</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>
    </div>
</div>
