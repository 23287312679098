<div class="container px-3">
    <div class="row g-3">
        <cdk-accordion class="meal-accordion">
            <cdk-accordion-item *ngFor="let meal of allMeals; let index = index;" #accordionItem="cdkAccordionItem"
                class="meal-accordion-item" role="button" tabindex="0" [expanded]="isToday(meal)"
                [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div class="meal-accordion-item-header" (click)="accordionItem.toggle()">
                    <h2 class="mb-0">{{formatDate(meal.date_to)}}</h2>
                    <button mat-icon-button class="btn-edit" color="primary"
                        [routerLink]="'/gerichte/'+ meal.id_meal + '/edit'">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div class="meal-accordion-item-body" role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index">
                    <div class="list-group list-group-flush">
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between">
                                <h3 class="mb-1">{{meal.breakfast}}</h3>
                            </div>
                            <small>Frühstück</small>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between">
                                <h3 class="mb-1">{{meal.lunch}}</h3>
                            </div>
                            <small>Mittag</small>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between">
                                <h3 class="mb-1">{{meal.dinner}}</h3>
                            </div>
                            <small>Abendbrot</small>
                        </div>
                    </div>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>

        <mat-paginator class="col-12" [length]="28" [pageSize]="7" [pageIndex]=2 (page)="getNext($event)">
        </mat-paginator>
    </div>
</div>