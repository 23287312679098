<div class="login-wrapper col-12 col-md-6 offset-md-3 mt-3 mb-3">
    <mat-card class="box ">
        <mat-card-header>
            <mat-card-title>Log in</mat-card-title>
        </mat-card-header>

        <form class="example-form" [formGroup]="loginForm">
            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Username" formControlName="username">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput type="password" placeholder="Password" formControlName="password">
                </mat-form-field>
            </mat-card-content>
            <button mat-flat-button color="primary" class="btn-block" (click)="login()">Anmelden</button>
        </form>
    </mat-card>
</div>
