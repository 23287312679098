import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../shared/api.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {IMeal, IMealFrontend} from '../../models/meals';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogSuccessComponent} from '../../dialog/dialog';


@Component({
    selector: 'app-meal-add',
    templateUrl: './meal-add.component.html',
    styleUrls: ['./meal-add.component.scss']
})
export class MealAddComponent implements OnInit {
    private subs = new Subscription();
    public mealAdd: FormGroup;
    public meal: IMeal;
    public mealOptions: any[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    idMeal: number;
    showLoading: boolean;
    filteredBreakfastOptions: Observable<any>;
    filteredLunchOptions: Observable<any>;
    filteredDinnerOptions: Observable<any>;
    dialogConfig = new MatDialogConfig();

    constructor(private fb: FormBuilder, private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog) {
        let user: any = localStorage.getItem('user') ? localStorage.getItem('user') : {};
        user = JSON.parse(user);
        console.log(user.id_user);
        this.mealAdd = this.fb.group({
            id_user: [user.id_user, Validators.required],
            date_to: ['', Validators.required],
            breakfast: [''],
            lunch: [''],
            dinner: [''],
        });
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;
        this.dialogConfig.data = {
            id: 1,
            title: 'Eintrag gespeichert',
            message: 'YEAAAAH! Dein Eintrag wurden erfolgreich gespeichert!',
            next: 'wochenplan'
        };
    }

    ngOnInit(): void {
        this.showLoading = false;
        this.subs.add(this.apiService.getMeals().subscribe(
            data => this.mealOptions = data,
            (err: HttpErrorResponse) => console.log(err)));
        this.filteredBreakfastOptions = this.mealAdd.controls.breakfast.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
        this.filteredLunchOptions = this.mealAdd.controls.lunch.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
        this.filteredDinnerOptions = this.mealAdd.controls.dinner.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
    }

    displayFn(meal: IMealFrontend): string {
        return meal && meal.title ? meal.title : '';
    }

    edit(): void {
        if (this.mealAdd.valid) {
            this.showLoading = true;
            const val = this.mealAdd.value;
            val.date_to = moment(val.date_to).format('YYYY-MM-DD');
            this.apiService.postMeals(val).subscribe((result) => {
                setTimeout(() => {
                    this.showLoading = false;
                    this.dialog.open(DialogSuccessComponent, this.dialogConfig);
                }, 1000);
            });
        }
    }

    private json_data_filter(value: any, matchStr: string[]): IMealFrontend[] {
        const newList: IMealFrontend[] = [];
        this.mealOptions.forEach(element => {
            matchStr.forEach(search => {
                if (element[`${search}`].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    const index = newList.findIndex((e) => e.title === element[`${search}`]);
                    if (index === -1 && element[`${search}`] !== '') {
                        newList.push({title: element[`${search}`]});
                    }
                }
            });
        });
        return newList;
    }

}
