import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../shared/api.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import {IMeal, IMealFrontend} from '../../models/meals';
import {map, startWith, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogSuccessComponent} from '../../dialog/dialog';

@Component({
    selector: 'app-meal-edit',
    templateUrl: './meal-edit.component.html',
    styleUrls: ['./meal-edit.component.scss']
})
export class MealEditComponent implements OnInit {
    private subs = new Subscription();
    public mealEdit: FormGroup;
    public meal: IMeal;
    public mealOptions: any[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    idMeal: number;
    showSuccess: boolean;
    showLoading: boolean;
    filteredBreakfastOptions: Observable<any>;
    filteredLunchOptions: Observable<any>;
    filteredDinnerOptions: Observable<any>;

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private route: ActivatedRoute,
                private dialog: MatDialog) {
        let user: any = localStorage.getItem('user') ? localStorage.getItem('user') : {};
        user = JSON.parse(user);
        const routeParams = this.route.snapshot.paramMap;
        this.mealEdit = this.fb.group({
            id_meal: ['', Validators.required],
            id_user: [user.id_user, Validators.required],
            date_to: ['', Validators.required],
            breakfast: [''],
            lunch: [''],
            dinner: [''],
        });
        this.idMeal = Number(routeParams.get('idMeal'));
    }

    ngOnInit(): void {
        this.getMeal();
        this.showSuccess = false;
        this.showLoading = false;
        this.subs.add(this.apiService.getMeals().subscribe(
            data => this.mealOptions = data,
            (err: HttpErrorResponse) => console.log(err)));
        this.filteredBreakfastOptions = this.mealEdit.controls.breakfast.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
        this.filteredLunchOptions = this.mealEdit.controls.lunch.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
        this.filteredDinnerOptions = this.mealEdit.controls.dinner.valueChanges.pipe(
            startWith(''),
            map(value => this.json_data_filter(value, ['breakfast', 'lunch', 'dinner']))
        );
    }

    getMeal(): void {
        this.apiService.getMeals('', '', this.idMeal).pipe(takeUntil(this.destroy$))
            .subscribe((meal: IMeal[]) => {
                this.mealEdit.patchValue({
                    id_meal: meal[0].id_meal,
                    date_to: meal[0].date_to,
                    breakfast: meal[0].breakfast,
                    lunch: meal[0].lunch,
                    dinner: meal[0].dinner,
                });
            });
    }

    closeSuccess(): void {
        this.showSuccess = false;
    }

    edit(): void {
        this.showLoading = true;
        const val = this.mealEdit.value;
        val.date_to = moment(val.date_to).format('YYYY-MM-DD');
        this.apiService.postMeals(val).subscribe((result) => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
                id: 1,
                title: 'Änderungen gespeichert',
                message: 'YEAAAAH! Deine Änderungen wurden erfolgreich geändert!',
                next: 'wochenplan'
            };
            setTimeout(() => {
                this.showLoading = false;
                this.dialog.open(DialogSuccessComponent, dialogConfig);
            }, 600);
        });
    }

    private json_data_filter(value: any, matchStr: string[]): IMealFrontend[] {
        const newList: IMealFrontend[] = [];
        this.mealOptions.forEach(element => {
            matchStr.forEach(search => {
                if (element[`${search}`].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    const index = newList.findIndex((e) => e.title === element[`${search}`]);
                    if (index === -1 && element[`${search}`] !== '') {
                        newList.push({title: element[`${search}`]});
                    }
                }
            });
        });
        return newList;
    }

}
